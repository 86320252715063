import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";
import { Query } from "../../graphql-types";

import {
  P,
  ImageWrapper,
  Section,
  SectionImg,
  TextWrapper,
  ArticleTitle,
  BackLink,
} from "../util/standard";
import { MOBILE_BREAKPOINT } from "../util/constants";
import DonateBlock from "../components/shared/DonateBlock";
import Gallery from "../components/shared/Gallery";
import RelatedPosts from "../components/news/RelatedPosts";
import SEO from "../components/shared/Seo";
import BlockContent from "@sanity/block-content-to-react";
import Video from "../components/shared/sub/Video";

const ArticleWrapper = styled.div`
  width: 100%;
  margin: 200px auto 100px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 150px auto 80px auto;
  }
`;

const Author = styled(P)`
  width: 60%;
  margin: 0 auto 100px auto;
`;

export const query = graphql`
  query newsArticleQuery($slug: String) {
    allSanityNews(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          articleTitle
          articleSubText
          articleDate
          articleGallery {
            images {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
                title
              }
            }
          }
          slug {
            current
          }
          _key
          contentSections {
            _key
            imageAlignRight
            sectionText {
              _rawContent
            }
            sectionImage {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  ...GatsbySanityImageFluid
                }
                title
              }
            }
          }
          articleVideo {
            videoSourceLink
            videoThumbnail {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
              }
            }
            _key
          }
          articleAuthor
          seoBlock {
            pageDescription
            pageKeyWords
            pageTitle
            ogImage {
              asset {
                url
              }
            }
          }
        }
      }
    }
    relatedPosts: allSanityNews(
      sort: { fields: articleDate, order: DESC }
      limit: 3
      filter: { slug: { current: { ne: $slug } } }
    ) {
      edges {
        node {
          articleTitle
          articleSubText
          articleDate
          articleImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
              title
            }
          }
          slug {
            current
          }
          _id
        }
      }
    }
  }
`;

interface Props extends PageProps {
  data: Query;
}

function NewsInner({ data }: Props) {
  const {
    allSanityNews: {
      edges: [{ node: article }],
    },
  } = data;

  return (
    <>
      <SEO seoData={article?.seoBlock} slug={article?.slug?.current} />
      <ArticleWrapper>
        <BackLink href="/news">{`<`} Back</BackLink>
        <ArticleTitle fontWeight="bold">{article.articleTitle}</ArticleTitle>
        <Author>Written by {article.articleAuthor}</Author>
        {article.contentSections.map(section => {
          if (section.imageAlignRight) {
            return (
              <Section key={section?._key} noImage={!section?.sectionImage}>
                {section.sectionImage && (
                  <ImageWrapper>
                    <SectionImg
                      fluid={section.sectionImage.asset.fluid}
                      alt={
                        section?.sectionImage.asset.title ??
                        "article-section-image"
                      }
                      imageStyle={{ objectFit: "contain" }}
                    />
                  </ImageWrapper>
                )}
                {section.sectionText && (
                  <TextWrapper>
                    <BlockContent blocks={section?.sectionText._rawContent} />
                  </TextWrapper>
                )}
              </Section>
            );
          } else {
            return (
              <Section key={section?._key} noImage={!section?.sectionImage}>
                {section.sectionText && (
                  <TextWrapper>
                    <BlockContent blocks={section?.sectionText._rawContent} />
                  </TextWrapper>
                )}
                {section.sectionImage && (
                  <ImageWrapper>
                    <SectionImg
                      fluid={section.sectionImage.asset.fluid}
                      alt={
                        section?.sectionImage.asset.title ??
                        "article-section-image"
                      }
                      imageStyle={{ objectFit: "contain" }}
                    />
                  </ImageWrapper>
                )}
              </Section>
            );
          }
        })}
      </ArticleWrapper>
      {article.articleVideo && article.articleVideo.videoSourceLink && (
        <Video
          videoSource={article.articleVideo.videoSourceLink}
          thumbnail={article.articleVideo.videoThumbnail}
        />
      )}
      {article.articleGallery !== null &&
      article.articleGallery?.images?.length >= 1 ? (
        <Gallery images={article.articleGallery} />
      ) : null}
      <RelatedPosts relatedPosts={data.relatedPosts?.edges} />
      <DonateBlock />
    </>
  );
}

export default NewsInner;
