import React from 'react'
import styled from 'styled-components'

import NewsGrid from './NewsGrid'
import { H1 } from '../../util/standard'
import { SanityNewsEdge } from '../../../graphql-types'

interface Props {
  relatedPosts: Array<SanityNewsEdge>;
}

const Title = styled(H1)`
color: black;
  width: 80%;
  margin: 0 auto 10.5px auto;
`

function RelatedPosts(props: Props) {

  const { relatedPosts } = props

  return (
    <>
      <Title fontWeight='bold'>Related Posts</Title>
      <NewsGrid articles={relatedPosts} />
    </>
  )
}

export default RelatedPosts
